a {
    color: black;
    text-decoration: none;
}
a:hover{
    text-decoration: none;
}

.c-blue{
    color: #007bff !important;
}
.c-green{
    color: green;
}
.c-red{
    color: red;
}
.p-relative{
    position: relative;
}
.font-sm{
    font-size: 12px;
}
.font-lg{
    font-size: 16px;
}



