* {
    margin: 0;
    padding: 0;
}

html,
body {
    height: 100%;
    scroll-behavior: smooth;
}


iframe {
    width: 100%;
    overflow: hidden;
}

::-webkit-scrollbar {
    width: 6px;
    height: 4px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 50px;
}

::-webkit-scrollbar-thumb {
    background: #293b7b;
    border-radius: 50px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.f-12 {
    font-size: 12px;
}

.p-relative {
    position: relative;
}

.c-pointer {
    cursor: pointer;
}

.i-icon {
    position: absolute;
    right: 12px;
    top: 12px;
}

.nav_logo {
    width: 180px;
    object-fit: contain;
    height: 65px;
}