.loader_div {
  width: 100%;
  height: 100vh;
  position: absolute;
  background: #1f1f1fad;
  z-index: 9999;
}

.spinner {
  width: 56px;
  height: 56px;
  display: grid;
  border: 4px solid #0000;
  border-radius: 50%;
  border-right-color: purple;
  animation: spinner-a4dj62 1s infinite linear;
}

.svgbox {
  --blue: rgb(148, 66, 63);
  stroke: var(--blue);
  stroke-width: 5;
  fill: none;
  stroke-dasharray: 50, 14;
  stroke-dashoffset: 192;
  animation: dash_682 1.4s linear infinite;
}


@keyframes dash_682 {
  72.5% {
    opacity: 1;
  }

  to {
    stroke-dashoffset: 1;
  }
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.loading {
  position: relative;
}

.svgbox {
  width: 100%;
  height: 100%;
}


@media (max-width: 600px) {
  .svgbox {
    width: 150px;
    height: 150px;
  }
}